var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('hr'),(_vm.$parent.det_inspecciones.length > 0)?_c('div',{staticClass:"row"},[_c('table',{staticClass:"table table-sm table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.$parent.det_inspecciones),function(item){return _c('tr',{key:item.id},[_c('th',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-sm btn-default py-0",attrs:{"type":"button"},on:{"click":function($event){return _vm.edit(item.id)}}},[_vm._v(" "+_vm._s(item.id)+" ")])]),_c('td',[_vm._v(_vm._s(item.punto_gestion ? item.punto_gestion.nombre : ""))]),_c('td',[_vm._v(_vm._s(item.formato_inspeccion.nombre))]),_c('td',[_vm._v(_vm._s(item.fecha_inicio))]),_c('td',{staticClass:"text-center"},[_c('span',{class:item.estado == 1
                  ? 'badge badge-secondary'
                  : item.estado == 2 || item.estado == 3
                  ? 'badge badge-success'
                  : 'badge badge-danger'},[_vm._v(" "+_vm._s(item.nEstado)+" ")])]),_c('td',{staticClass:"text-center"},[_c('span',{class:item.estado_documento == 1
                  ? 'badge badge-warning'
                  : item.estado_documento == 2
                  ? 'badge badge-success'
                  : 'badge badge-danger'},[_vm._v(" "+_vm._s(item.nEstadoDocumento)+" ")])]),_c('td',{staticClass:"text-right"},[(item.link)?_c('div',{staticClass:"btn-group text-center"},[_c('a',{staticClass:"btn btn-info btn-sm",attrs:{"target":"_blank","href":item.link}},[_c('i',{staticClass:"fa fa-download"})])]):_vm._e()])])}),0)])]):_c('div',{staticClass:"row"},[_vm._m(1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-dark"},[_c('tr',{staticClass:"text-center"},[_c('th',{attrs:{"scope":"col"}},[_vm._v("Número")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Punto Gestión")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Formato Inspección")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Fecha")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Estado")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Estado Documento")]),_c('th',{attrs:{"scope":"col"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert alert-warning col-md-12"},[_c('h5',[_c('i',{staticClass:"icon fas fa-exclamation-triangle"}),_vm._v(" Atención!")]),_vm._v(" No se realizaron Inspecciones a este Viaje! ")])
}]

export { render, staticRenderFns }