<template>
  <div>
    <hr />
    <div class="row" v-if="$parent.det_inspecciones.length > 0">
      <table class="table table-sm table-hover">
        <thead class="thead-dark">
          <tr class="text-center">
            <th scope="col">Número</th>
            <th scope="col">Punto Gestión</th>
            <th scope="col">Formato Inspección</th>
            <th scope="col">Fecha</th>
            <th scope="col">Estado</th>
            <th scope="col">Estado Documento</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in $parent.det_inspecciones" :key="item.id">
            <th class="text-center">
              <button
                type="button"
                class="btn btn-sm btn-default py-0"
                @click="edit(item.id)"
              >
                {{ item.id }}
              </button>
            </th>
            <td>{{ item.punto_gestion ? item.punto_gestion.nombre : "" }}</td>
            <td>{{ item.formato_inspeccion.nombre }}</td>
            <td>{{ item.fecha_inicio }}</td>
            <td class="text-center">
              <span
                :class="
                  item.estado == 1
                    ? 'badge badge-secondary'
                    : item.estado == 2 || item.estado == 3
                    ? 'badge badge-success'
                    : 'badge badge-danger'
                "
              >
                {{ item.nEstado }}
              </span>
            </td>
            <td class="text-center">
              <span
                :class="
                  item.estado_documento == 1
                    ? 'badge badge-warning'
                    : item.estado_documento == 2
                    ? 'badge badge-success'
                    : 'badge badge-danger'
                "
              >
                {{ item.nEstadoDocumento }}
              </span>
            </td>
            <td class="text-right">
              <div class="btn-group text-center" v-if="item.link">
                <a target="_blank" class="btn btn-info btn-sm" :href="item.link"
                  ><i class="fa fa-download"></i
                ></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row" v-else>
      <div class="alert alert-warning col-md-12">
        <h5><i class="icon fas fa-exclamation-triangle"></i> Atención!</h5>
        No se realizaron Inspecciones a este Viaje!
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ViajesInfoInspecciones",

  data() {
    return {
      det_inspecciones: {},
    };
  },
  methods: {
    edit(inspeccion_id) {
      this.$parent.$refs.closeModal.click();
      return this.$router.push({
        name: "/Hidrocarburos/InspeccionForm",
        params: {
          inspeccion_id: inspeccion_id,
        },
      });
    },
  },

  mounted() {},
};
</script>
